import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { DatePipe } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { finalize } from 'rxjs/operators';
import { LoadingDialogService } from '../services/ui/loading-dialog.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NotificationService } from '../services/ui/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'rd-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  providers: [DatePipe]
})

export class SubscriptionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @Input() sidenavOpenedValue: boolean;

  sidenavOpened = false;
  darkMode: boolean;
  panelClass: string;
  showFiller = false;
  showHeadings: boolean = true;
  displayedColumns: string[] = ['Meid', 'RANFunctionName', 'SubscriptionId', 'Action'];
  dataSource = new MatTableDataSource<any>();
  form: FormGroup;
  gnbNodeData: any = {};
  dropdown1Options: string[] = [];
  dropdown2Options: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private xappOnboarderService: XappOnboarderService,
    private loadingDialogService: LoadingDialogService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private router: Router,

  ) { }

  ngOnInit() {
    this.getTableData();
    this.loadData();
    this.form = this.fb.group({
      dropdown1: ['', Validators.required ],
      dropdown2: ['', Validators.required]
    });

    this.form.get('dropdown1')?.valueChanges.subscribe(selectedValue => {
      this.getDropdown2Options(selectedValue);
    });
  }

  getTableData() {
    this.xappOnboarderService.getSubscriptionData().subscribe(
      (response: any) => {
        this.dataSource.data = JSON.parse(response);
        let data = this.dataSource.data.map((x) => {
          if (x.RANFunctionOID === "1.3.6.1.4.1.53148.1.2.2.2") {
            x.RANFunctionOID = "E2SM-KPM-V2";
          }
          if (x.RANFunctionOID === "1.3.6.1.4.1.53148.1.1.2.2") {
            x.RANFunctionOID = "E2SM-KPM-V1";
          }
          if (x.RANFunctionOID === "1.3.6.1.4.1.53148.1.1.2.3") {
            x.RANFunctionOID = "E2SM-RC";
          }
          if (x.RANFunctionOID === "1.3.6.1.4.1.53148.1.1.2.4") {
            x.RANFunctionOID = "E2SM-CCC";
          }
        })
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  formatDate(dateString: string): string {
    return this.datePipe.transform(dateString, 'MMM d, y, h:mm:ss a') || '';
  }

  // Reload the entire page
  refreshPage(): void {
    window.location.reload();
  }

  onboard(): void {
    if (this.darkMode) {
      this.panelClass = 'dark-theme';
    } else {
      this.panelClass = '';
    }
    this.showFiller = true;
  }

  toggleHeadings() {
    this.showHeadings = !this.showHeadings;
  }

  toggleSidenav() {
    this.sidenavOpened = !this.sidenavOpened;
    if (this.sidenavOpened) {
      this.sidenav.open();
    } else {
      this.sidenav.close();
    }
  }

  deleteSubscription(id: any) {
    this.xappOnboarderService.deleteSubscriptionData(id).pipe(
      finalize(() => {
        this.getTableData();
        this.loadingDialogService.stopLoading();
      })
    )
      .subscribe(
        (response: HttpResponse<Object>) => {
          this.xappOnboarderService.Refreshrequired.subscribe(Response => {
            this.getTableData();
          })
          this.notificationService.success('App delete succeeded!');
        },
        ((her: HttpErrorResponse) => {
          this.notificationService.warn("App delete failed");
          let msg = her.message;
          if (her.error && her.error.message) {
            msg = her.error.message;
          }
        })
      );
  }

  loadData() {
    this.xappOnboarderService.getGnbConnectedInventoryNames().subscribe(async (response) => {
      this.gnbNodeData = response;
      if (Array.isArray(JSON.parse(this.gnbNodeData).data)) {
        this.dropdown1Options = JSON.parse(this.gnbNodeData).data.map(item => Object.keys(item)[0]);
      } else {
        console.error('API response does not contain an array:', JSON.parse(this.gnbNodeData).data);
      }
      });
  }

  getDropdown2Options(selectedNode: string): void {
    if (JSON.parse(this.gnbNodeData).data) {
    const selectedData = JSON.parse(this.gnbNodeData).data.find(item => item.hasOwnProperty(selectedNode));
    this.dropdown2Options = selectedData
      ? Object.keys(selectedData[selectedNode]).filter((key: string) => selectedData[selectedNode][key] === true && key !== 'Connected')
      : [];
    } else {
      this.dropdown2Options = [];
    }
  }

  submitSelection() {
    // if (this.form.get('dropdown2').value === "E2SM-KPM") {
    //   let property = "1.3.6.1.4.1.53148.1.2.2.2"
    //   this.form.get('dropdown2').setValue(property);
    // }
    if (this.form.get('dropdown2').value === "E2SM-KPM") {
      let property = "1.3.6.1.4.1.53148.1.1.2.2"
      this.form.get('dropdown2').setValue(property);
    }
    if (this.form.get('dropdown2').value === "E2SM-RC") {
      let property = "1.3.6.1.4.1.53148.1.1.2.3"
      this.form.get('dropdown2').setValue(property);
    }
    if (this.form.get('dropdown2').value === "E2SM-CCC") {
      let property = "1.3.6.1.4.1.53148.1.1.2.4"
      this.form.get('dropdown2').setValue(property);
    }
    const selectedData = {
      node: this.form.get('dropdown1').value,
      property: this.form.get('dropdown2').value
    };

    if (selectedData.node && selectedData.property) {
      this.xappOnboarderService.postCreateSubscription(selectedData)
        .subscribe(
          (response: HttpResponse<Object>) => {
            this.xappOnboarderService.Refreshrequired.subscribe(Response => {
            })
            this.notificationService.success('Create subscription successfully!');
            this.onboard(); this.toggleSidenav(); this.toggleHeadings();
            this.loadData();
            this.getTableData();
            this.form.reset();
          },
          ((her: HttpErrorResponse) => {
            this.notificationService.warn("Create subscription failed");
            this.form.reset();
            let msg = her.message;
            if (her.error && her.error.message) {
              msg = her.error.message;
            }
          })
        );
    }
  }

}

