import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { btoa } from 'js-base64';
import { AuthGuardServiceService } from '../services/auth-guard/auth-guard-service.service';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { AuthGuardGuard } from '../services/authService/auth-guard.guard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit{
  isPassword: boolean = true;
  submitted = false;
  error = '';
  hide = true;

  username: string = 'admin';
  password: string = 'password123';
  errorMessage: string = '';

  private readonly hardcodedUsername: string = 'admin';
  private readonly hardcodedPassword: string = 'password123';

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required,]),
    password: new FormControl('', [Validators.required])


  });
  headerProperty: any;

  constructor(
    private api: XappOnboarderService,
    private formBuilder: FormBuilder,
    public authService: AuthGuardServiceService,
    public router: Router,
    public authGuard:AuthGuardGuard
    
  ) {

  }

  ngOnInit(): void {
    
  }

  get formControl() {
    return this.loginForm.controls;
  }

  signIn() {
    // if (!this.loginForm.valid) {
    //   this.loginForm.markAllAsTouched();
    // } else {
    // }
    // let requestBody = {
    //   username: this.loginForm.get("email")?.value,
    //   password: btoa(this.loginForm.get("password")?.value)
    // }

    // this.api.login(requestBody).subscribe((response: any) => {
    //   this.headerProperty = response;
    //   if (this.headerProperty.status == 200) {
    //     this.router.navigate(['/home']);
    //   }
      
    // },
    // error => {
    //   console.error('Login failed', error);
    // });

//hardcode data for login 


if (this.username === this.hardcodedUsername && this.password === this.hardcodedPassword) {
  // Navigate to the dashboard
  this.router.navigate(['/Dashboard']);
} else {
  this.errorMessage = 'Invalid credentials. Please try again.';
}
}
  }

