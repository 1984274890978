<form [formGroup]="uploadFromLocalfile" (ngSubmit)="uploadFromLocal()" action="upload" method="post"
  enctype="multipart/form-data">
  <div mat-dialog-title>Register Xapp</div>
  <mat-dialog-content>
    <input #configInput type="file" accept=".zip" (change)="onFileSelected($event); handleCategoryBanner2($event)" #file
      style="display: none" ngModel />
    <mat-form-field appearance="outline" class="example-full-width customApp" (click)="configInput.click()">
      <mat-label>Upload Xapp Package</mat-label>
      <input matInput placeholder="" accept=".zip,.rar,.7zip" formControlName="signed_xapp_file" value="{{
          configInput.value.substr(configInput.value.lastIndexOf('\\') + 1)
        }}" />
      <mat-icon class="folder_open" matSuffix>folder_open</mat-icon>
    </mat-form-field>
    <input #schemaInput type="text" style="display: none" ngModel />
    <mat-form-field appearance="outline" class="example-full-width" (click)="schemaInput.click()">
      <mat-label>Xapp Name</mat-label>
      <input matInput placeholder="" formControlName="xapp_name" value="{{
          schemaInput.value.substr(schemaInput.value.lastIndexOf('\\') + 1)
        }}" />
      <mat-icon matSuffix></mat-icon>
    </mat-form-field>
    <input #schemaInput type="text" style="display: none" ngModel />
    <mat-form-field appearance="outline" class="example-full-width" (click)="schemaInput.click()">
      <mat-label>Xapp Version</mat-label>
      <input matInput placeholder="" formControlName="xapp_version" value="{{
          schemaInput.value.substr(schemaInput.value.lastIndexOf('\\') + 1)
        }}" />
      <mat-icon matSuffix></mat-icon>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" type="submit">Register</button>
    <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
  </mat-dialog-actions>
</form>