import { Component, OnInit, ViewChild } from '@angular/core';
import { AppControlDataSource } from '../app-control/app-control.datasource';
import { Subscription } from 'rxjs-compat';
import { MatSort } from '@angular/material/sort';
import { AppMgrService } from '../services/app-mgr/app-mgr.service';
import { ErrorDialogService } from '../services/ui/error-dialog.service';
import { InstanceSelectorService } from '../services/instance-selector/instance-selector.service';
import { NotificationService } from '../services/ui/notification.service';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { UiService } from '../services/ui/ui.service';
import { RicInstance } from '../interfaces/dashboard.types';
import { XappControlRow } from '../interfaces/app-mgr.types';

@Component({
  selector: 'rd-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss']
})
export class AlarmComponent implements OnInit {
  alarms: any[];
  darkMode: boolean;
  sharedData: string;
  panelClass: string;
  displayedColumns: string[] = ['alarmId', 'alarmText', 'eventType'];
  displayedColumns2: string[] = [ 'identifyingInfo', 'perceivedSeverity','AlarmAction'];
  dataSource: AppControlDataSource;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private instanceChange: Subscription;
  private instanceKey: string;
  receivedData: any =[];
  receivedData2:any=[];
  
  constructor(
    private appMgrSvc: AppMgrService,
    private errorDialogService: ErrorDialogService,
    public instanceSelectorService: InstanceSelectorService,
    private notificationService: NotificationService,
    public ui: UiService,
    private xappOnboarderService: XappOnboarderService,
  ) { }


  ngOnInit() {

    this.getAlarmDefinitions();
    this.getAlarm()
    this.dataSource = new AppControlDataSource(this.appMgrSvc, this.sort, this.notificationService);
    this.ui.darkModeState.subscribe((isDark) => {
      this.darkMode = isDark;
    });
    this.instanceChange = this.instanceSelectorService.getSelectedInstance().subscribe((instance: RicInstance) => {
      if (instance.key) {
        this.instanceKey = instance.key;
      }
    });


  }


  public onClickdata(value:any) {
		this.instanceSelectorService.changeMessage(value)
	}

  

  controlApp(app: XappControlRow): void {
    this.errorDialogService.displayError('No control available for ' + app.xapp + ' (yet)');
  }

  /*----- **
    Function : getAlarmDefinitions()
    Description : This function is to show the listing of the particular running alarm defination on alarm component . 
     -------**/


  getAlarmDefinitions() {
    this.xappOnboarderService.getAlarmDefinitions().subscribe({
      next: (res) => {
        this.receivedData=[];
        let data = JSON.parse(res);
        let Original = Object.entries(data);
         let mainArray: any = [];

        for (let i = 0; i < Original.length; i++) {
          mainArray.push(Original[i][1])

        }
        const newData = mainArray.flat();
        this.receivedData=(data.alarmdefinitions);
      },
      error: console.log,

    }) 
  }


/*----- **
 Function : getAlarmDefinitions()
 Description : This function is to show the listing of the particular running alarm defination on alarm component . 
  -------**/
getAlarm() {
  this.xappOnboarderService.getAlarm().subscribe({
    next: (res) => {
      let data = JSON.parse(res);
      let Original = Object.entries(data);
      let mainArray2: any = [];

      for (let i = 0; i < Original.length; i++) {
        mainArray2.push(Original[i][1])

      }
      const newData = mainArray2.flat();
      this.receivedData2 = (data);
    },
    error: console.log,

  })
}

// Reload the entire page
refreshPage(): void { 
  window.location.reload();
}

ngOnDestroy() {
  this.instanceChange.unsubscribe();
}
}