import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthGuardServiceService } from './services/auth-guard/auth-guard-service.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  xml: any;

  constructor(public authService: AuthGuardServiceService,) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      // proceed when there is a response; ignore other events
      filter(event => event instanceof HttpResponse),
      map(
        (event: HttpResponse<any>) => {
          
          this.authService?.setToken(event?.headers?.get('Authorization'))

                      
          // {responseType: text} expects a string response
          return event.clone({ body: JSON.stringify(event.body) }); 
        })
    );
  }
}
