/*-
 * ========================LICENSE_START=================================
 * O-RAN-SC
 * %%
 * Copyright (C) 2019 AT&T Intellectual Property
 * %%
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *      http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================LICENSE_END===================================
 */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardSuccessTransport } from '../../interfaces/dashboard.types';
import { E2RanDetails } from '../../interfaces/e2-mgr.types';

@Injectable({
  providedIn: 'root'
})

export class E2ManagerService {

  private component = 'e2mgr';
  private nodebPath = 'nodeb';

  constructor(
    private httpClient: HttpClient,
    private http: HttpClient) {
  }
  headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization" :localStorage.getItem('id_token')  
    }),
  };

  /**
   * Gets E2 client version details
   * @returns Observable that yields a String
   */
  

  /**
   * Gets RAN details
   * @returns Observable that yields an array of E2RanDetails objects
   */
 

  /**
   * Sends a request to drop all RAN connections
   * @returns Observable with body.
   */
  


  getlogout():Observable<any>{
    return this.http.get("http://35.238.94.32:32080/e2mgr/authenticate/logout",this.headers);
  }
}
