import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs-compat';
import { AppMgrService } from '../services/app-mgr/app-mgr.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogService } from '../services/ui/loading-dialog.service';
import { InstanceSelectorService } from '../services/instance-selector/instance-selector.service';
import { NotificationService } from '../services/ui/notification.service';
import { UiService } from '../services/ui/ui.service';
import { RicInstance } from '../interfaces/dashboard.types';
import { MatTableDataSource } from '@angular/material/table';
import { interval } from 'rxjs';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { element } from 'protractor';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';


@Component({
  selector: 'rd-gnblist',
  templateUrl: './gnblist.component.html',
  styleUrls: ['./gnblist.component.scss']
})
export class GnblistComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    // this.dataSource.sort=sort;
  };

  refreshPage(): void {
    // Reload the entire page
    window.location.reload();
  }
  private updateSubscription: Subscription | undefined;
  dataSubject: any = {};
  darkMode: boolean;
  panelClass: string;
  displayedColumns: string[] = ['Inventory_name', 'plmn_id', 'nbid', 'connection_status'];
  displayedColumns2: string[] = ['CCC', 'KPM', 'RC'];
  private instanceChange: Subscription;
  private instanceKey: string;
  dataSource!: MatTableDataSource<any>;
  dataSource2!: MatTableDataSource<any>;
  isSidebarOpen: boolean = false;
  selectedInventory: any;
  items: any;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private loadingDialogService: LoadingDialogService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    public instanceSelectorService: InstanceSelectorService,
    private xappOnboarderService: XappOnboarderService,
    public ui: UiService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.xappOnboarderService.Refreshrequired.subscribe(Response => {
      this.getGnb();
      this.updateSubscription = interval(1000).subscribe(() => {
        this.getGnb();
        //  this.getInventoryName(inventory)
      });
    })
    this.getGnb();
    this.ui.darkModeState.subscribe((isDark) => {
      this.darkMode = isDark;
    });

    this.instanceChange = this.instanceSelectorService.getSelectedInstance().subscribe((instance: RicInstance) => {
      if (instance.key) {
        this.instanceKey = instance.key;
      }
    });
  }

  ngOnDestroy() {
    this.instanceChange.unsubscribe();
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  /*----- **
   Function : getGnb() 
   Description : This function is to determine the chart list on gnblist screen 
    -------**/

  getGnb() {
    this.xappOnboarderService.getGnb().subscribe({
      next: (res) => {
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray: any = [];
        for (let i = 0; i < Original.length; i++) {
          mainArray.push(Original[i][1])
        }
        const newData = mainArray.flat();
        this.dataSource = new MatTableDataSource(newData);
        this.changeDetectorRef.detectChanges();
      },
      error: console.log,
    })
  }

  // open sidebar 
  openSidebar(inventory: any) {
    this.selectedInventory = inventory;
    this.isSidebarOpen = true;
    this.cdr.detectChanges();
    this.getInventoryName(inventory);
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  getInventoryName(inventory) {
    this.xappOnboarderService.getInventoryName(inventory).subscribe({
      next: (res) => {
        let data = JSON.parse(res);
        const kpmArray = data.find(obj => obj.hasOwnProperty('KPM')).KPM;

        // Creating the desired format
        const result = kpmArray.map(kpm => ({
          "CCC": (data.CCC ? data.find(obj => obj.hasOwnProperty('CCC')).CCC : '-'),
          "KPM": kpm,
          "RC": (data.RC ? data.find(obj => obj.hasOwnProperty('RC')).RC : '-')
        }));

        this.dataSource2 = new MatTableDataSource(result);
        this.changeDetectorRef.detectChanges();
      },
      error: console.log,
    })
  }

  onCellClick(name: any, route: string) {
    this.router.navigate([route, name.inventoryName], { queryParams: { header: 'gnbinfo' } });
  }
}

