<div class="header">
  <h3>xApp Catalog</h3>
  <div class="right-action">
    <button mat-mini-fab (click)="toggleSlide()">
      <span class="material-symbols-outlined"> quick_reference_all </span>
    </button>
    <button mat-raised-button color="primary" (click)="onboard()">
      Onboard Xapp
    </button>
  </div>
</div>

<div class="app-control__section">
  <table mat-table [dataSource]="dataSource" matSort class="catalog-table">
    <!-- xapp_name  -->
    <ng-container matColumnDef="xapp_name">
      <mat-header-cell *matHeaderCellDef> App Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <!-- xapp_version -->
    <ng-container matColumnDef="xapp_version">
      <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.version }} </mat-cell>
    </ng-container>

    <!-- xapp_action -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="catalog-button-row d-flex">
          <button
            mat-icon-button
            matTooltip="Deploy"
            (click)="onDeployApp(element)"
            class="customBtn mat-icon-button"
          >
            <span class="material-symbols-outlined"> cloud_upload </span>
          </button>
          <button
            mat-icon-button
            matTooltip="Delete"
            (click)="deleteXapp(element)"
            class="customBtn mat-icon-button"
          >
            <span class="material-symbols-outlined"> delete </span>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="noRecordsFound">
      <mat-footer-cell *matFooterCellDef>No records found.</mat-footer-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
</div>

<!-- page details -->

<div class="page-details" [ngClass]="{ slideShow: isToggled }">
  <div class="page-details-head">
    <div class="head-title">Details Page</div>
    <button mat-mini-fab  (click)="toggleSlide()">
      <span class="material-symbols-outlined"> close </span>
    </button>
  </div>
  <div class="page-details-body">
    <rd-details></rd-details>

  </div>
  <div class="overlay-bg"  (click)="toggleSlide()"></div>
</div>

