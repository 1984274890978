/*-
 * ========================LICENSE_START=================================
 * O-RAN-SC
 * %%
 * Copyright (C) 2019 AT&T Intellectual Property
 * %%
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *      http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================LICENSE_END===================================
 */
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { XappControlRow } from '../interfaces/app-mgr.types';
import { RicInstance } from '../interfaces/dashboard.types';
import { AppMgrService } from '../services/app-mgr/app-mgr.service';
import { InstanceSelectorService } from '../services/instance-selector/instance-selector.service';
import { ConfirmDialogService } from '../services/ui/confirm-dialog.service';
import { ErrorDialogService } from '../services/ui/error-dialog.service';
import { LoadingDialogService } from '../services/ui/loading-dialog.service';
import { NotificationService } from '../services/ui/notification.service';
import { UiService } from '../services/ui/ui.service';
// import { AppConfigurationComponent } from './../app-configuration/app-configuration.component';
import { AppControlAnimations } from './app-control.animations';
import { AppControlDataSource } from './app-control.datasource';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { OnboardComponent } from '../onboard/onboard.component';

@Component({
  selector: 'rd-app-control',
  templateUrl: './app-control.component.html',
  styleUrls: ['./app-control.component.scss'],
  animations: [AppControlAnimations.messageTrigger]
})
export class AppControlComponent implements OnInit, OnDestroy {

  darkMode: boolean;
  sharedData: string;
  panelClass: string;
  displayedColumns: string[] = [ 'name','namespace', 'status','creationTime', 'action'];
  dataSource: AppControlDataSource;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private instanceChange: Subscription;
  private instanceKey: string;
  receivedData:any=[];
  

  constructor(
    private appMgrSvc: AppMgrService,
    private router: Router,
    private dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService,
    private errorDialogService: ErrorDialogService,
    private loadingDialogService: LoadingDialogService,
    public instanceSelectorService: InstanceSelectorService,
    private notificationService: NotificationService,
    public ui: UiService,
    private xappOnboarderService: XappOnboarderService,
) { }


  ngOnInit() {
   this.dataSource = new AppControlDataSource(this.appMgrSvc, this.sort, this.notificationService);
    this.ui.darkModeState.subscribe((isDark) => {
      this.darkMode = isDark;
    });
    this.xappOnboarderService.Refreshrequired.subscribe(Response=>{
      this.controlChartXappFile();
       }
    )
    this.controlChartXappFile();
    this.instanceChange = this.instanceSelectorService.getSelectedInstance().subscribe((instance: RicInstance) => {
      if (instance.key) {
        this.instanceKey = instance.key;
      }
    });

  
  
    this.instanceSelectorService.uploadSuccess$.subscribe((uploadedData) => {
      // Call API with uploadedData
      console.log('Calling API with uploaded data:', uploadedData);
      this.controlChartXappFile();
    });
   }
  

  ngOnDestroy() {
    this.instanceChange.unsubscribe();
  }

  controlApp(app: XappControlRow): void {
    this.errorDialogService.displayError('No control available for ' + app.xapp + ' (yet)');
  }

  refreshPage(): void {
    // Reload the entire page
    window.location.reload();
  }
  

  /*----- **
   Function : undeploy()
   Description : This function is to undeploy the specific xapp name and version on catalog screen 
    -------**/

    undeploy(xapp: any) {  
    
      let data = {
        xapp_name:xapp.chartName,
      }
  
      this.xappOnboarderService.undeployXappFile(data)
        .pipe(
          finalize(() => {
            this.loadingDialogService.stopLoading();
          })
        )
        .subscribe(
          (response: HttpResponse<Object>) => {
            this.notificationService.success('App undeploy succeeded!');
            this.controlChartXappFile();
      
          },
          ((her: HttpErrorResponse) => {
            this.notificationService.warn("App undeploy failed");
            let msg = her.message;
            if (her.error && her.error.message) {
              msg = her.error.message;
            }
            
          })
      );
     
    }

 /*----- **
   Function : controlChartXappFile()
   Description : This function is to show the listing of the particular running xapp on control screen. 
    -------**/


  controlChartXappFile() {
    this.xappOnboarderService.controlChartXappFile().subscribe({
      next: (res) => {
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray2: any = [];
  
        for (let i = 0; i < Original.length; i++) {
          mainArray2.push(Original[i][1])
  
        }
        const newData = mainArray2.flat();
        this.receivedData = newData.map(item => {
          return {
            ...item,  
            name: item.name.split('-').slice(0, -2).join('-')  
          };
        });
        console.log(this.receivedData, "newdata")

      },
      error: console.log,
  
    })
  }


  
  onboard(): void {
    if (this.darkMode) {
      this.panelClass = 'dark-theme';
    } else {
      this.panelClass = '';
    }
    const dialogRef = this.dialog.open(OnboardComponent, {
      panelClass: this.panelClass,
      width: '400px',
      maxHeight: '1000px',
      position: {
        top: '10%'
      },
      data: {
        instanceKey: this.instanceKey
      }
    });
  }
}
