<div class="header">
  <div class="right-action">
    <button mat-mini-fab (click)="refreshPage()">
      <span class="material-symbols-outlined">refresh</span>
    </button>
    <button mat-raised-button color="primary" (click)="onboard(); toggleSidenav(); toggleHeadings();">
      create Subscription <mat-icon>{{ sidenavOpened ? 'remove' : 'add' }}</mat-icon>
    </button>
  </div>
</div>

<div class="app-control__section " *ngIf="showHeadings">
  <table mat-table [dataSource]="dataSource" class="app-control-table">
    <!-- Created Column -->
    <!-- <ng-container matColumnDef="Created">
      <ng-container>
        <th mat-header-cell *matHeaderCellDef> Created </th>
      </ng-container>
      <td mat-cell *matCellDef="let element"> {{formatDate(element.Created)}} </td>
    </ng-container> -->

    <!-- Meid Column -->
    <ng-container matColumnDef="Meid">
      <ng-container>
        <th mat-header-cell *matHeaderCellDef> gNodeB Name </th>
      </ng-container>
      <td mat-cell *matCellDef="let element"> {{element.Meid}} </td>
    </ng-container>

    <!-- InstanceIds Column -->
    <ng-container matColumnDef="SubscriptionId">
      <ng-container>
        <th mat-header-cell *matHeaderCellDef> Subscription Id </th>
      </ng-container>
      <td mat-cell *matCellDef="let element"> {{element.SubscriptionId}} </td>
    </ng-container>

    <!-- SubReqOngoing Column -->
    <ng-container matColumnDef="RANFunctionName">
      <ng-container>
        <th mat-header-cell *matHeaderCellDef> RAN Function Name </th>
      </ng-container>
      <td mat-cell *matCellDef="let element"> {{element.RANFunctionOID}} </td>
    </ng-container>

    <!-- SubDelReqOngoing Column -->
    <!-- <ng-container matColumnDef="SubDelReqOngoing">
      <ng-container>
        <th mat-header-cell *matHeaderCellDef> SubDelReqOngoing </th>
      </ng-container>
      <td mat-cell *matCellDef="let element"> {{element.SubDelReqOngoing}} </td>
    </ng-container> -->

    <!-- Action Column -->
    <ng-container matColumnDef="Action">
      <ng-container>
        <th mat-header-cell *matHeaderCellDef> Delete Subscription </th>
      </ng-container>
      <td mat-cell *matCellDef="let element" (click)="deleteSubscription(element.SubscriptionId)"><mat-icon
          class="delete_container">delete_outline</mat-icon></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="noDataMsg">{{dataSource.data.message}}</div>

</div>

<mat-sidenav-container class="example-container example-container2" autosize>
  <mat-sidenav #sidenav class="example-sidenav" mode="side" position="end">
    <!-- <rd-onboard-subscription></rd-onboard-subscription> -->
    <div class="catalog-container">
      <form [formGroup]="form" (ngSubmit)="submitSelection()">

        <div class="form-row">

          <div class="form-group">

            <mat-form-field appearance="fill">
              <mat-label>Gnode B </mat-label>
              <mat-select formControlName="dropdown1">
                <mat-option *ngFor="let option of dropdown1Options" [value]="option">{{ option }}</mat-option>
              </mat-select>
              <!-- Show error only after field is touched -->
              <mat-error *ngIf="form.get('dropdown1')?.touched && form.get('dropdown1')?.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-group1">
            <mat-form-field appearance="fill">
              <mat-label>Ran Function</mat-label>
              <mat-select formControlName="dropdown2">
                <mat-option *ngFor="let option of dropdown2Options" [value]="option">{{ option }}</mat-option>
              </mat-select>
              <!-- Show error only after field is touched -->
              <mat-error *ngIf="form.get('dropdown2')?.touched && form.get('dropdown2')?.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="form-actions">
          <button mat-raised-button color="primary" [disabled]="form.invalid">Submit</button>
        </div>
      </form>
    </div>
  </mat-sidenav>
</mat-sidenav-container>