<div class="login">
	<div class="loginInfo">
		<img src="/assets/icon.png" alt="5G Network Management System">
		<span>
			<h1>Welcome to,</h1>
			<p>5G network management system</p>
		</span>
	</div>

	<div class="form loginForm">
		<form [formGroup]="loginForm" (ngSubmit)="signIn()" autocomplete="off">
			<div class="formBody">
				<div class="logo">
					<img src="/assets/logo.png" width="200" alt="5G Network Management System">
				</div>
				<h2>Login <span>NEAR RT-RIC</span></h2>
				<div class="grid">
					<div class="col-12">
						<div class="relative">
							<span class="p-float-label">
								 <mat-form-field class="patternNotMatch">
									<input matInput  id="email" [ngClass]="{'is-invalid':submitted && loginForm.controls.password.errors}" formControlName="email" required>
								</mat-form-field>
								<label>Email</label>
							</span>
							<mat-error>
								<div *ngIf="formControl.email.invalid && (formControl.email.dirty || formControl.email.touched || formControl.email.untouched)" class="form-text text-danger">
								<div class="p-error" *ngIf="formControl.email.errors?.required">
									<small>*Must Enter Email</small>
								</div>
								<div class="p-error" *ngIf="formControl.email.errors?.pattern">
									<small>Invalid Email</small>
								</div>
								</div>
							</mat-error>
						</div>
					</div>
					<div class="col-12">
						<div class="relative">
							<span class="p-float-label">
								<mat-form-field class="patternNotMatch">
									<input matInput [type]="hide ? 'password' : 'text'" formControlName="password" id="password" required>
								</mat-form-field>
								<label>Password</label>
								<span class="visiblePass" (click)="hide = !hide">
									<img [src]="hide ?  '../../assets/svg/hide-password.svg':'../../assets/svg/show-password.svg' " width="23" alt="">
								</span>
							</span>
							<mat-error>
								<div *ngIf="formControl.password.invalid && (formControl.password.dirty || formControl.password.touched || formControl.password.untouched)" class="form-text text-danger">
								  <div class="p-error" *ngIf="formControl. password.errors?.required">
									<small>*Must Enter Password</small>
								  </div>
								  <div class="p-error" *ngIf="formControl.password.errors?.pattern">
									<small>Field is required</small>
								  </div>
								</div>
							  </mat-error>
						</div>
					</div>
					<div class="col-12">
						<button pButton type ="submit" class="loginBtn" label="">LOGIN</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
