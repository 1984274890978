import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

interface FoodNode {
  name: string;
  count?: number;
  children?: FoodNode[];
  rc?:string;
  ccc?:string;
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'OID supported',
    children: [
      {name: 'KPM', count: 10},
      {name: 'RC', count: 20},
      {name: 'CCC', count: 30},
    ]
  }, 
  {
    name: 'Event Trigger Styles supported ',
    children: [
      {
        name: 'KPM',
        children: [
          {name: 'SUB KPM', count: 10},
          {name: 'SUB RC', count: 20},
          {name: 'SUB CCC', count: 20},
        ]
      }, {
        name: 'RC',
        children: [
          {name: '', count: 30},
          {name: '', count: 40},

        ]
      },
      {
        name: 'CCC',
        children: [
          {name: '', count: 30},
          {name: '', count: 40},
          
        ]
      },
    ]
  },
  {
    name: 'Report Styles supported',
    children: [
      {name: 'KPM', count: 10},
      {name: 'RC', count: 20},
      {name: 'CCC', count: 30},
    ]
  }, 
  {
    name: 'Indication Header Formats',
    children: [
      {name: 'KPM', count: 10},
      {name: 'RC', count: 20},
      {name: 'CCC', count: 30},
    ]
  }, 
  {
    name: 'Indication Message Formats',
    children: [
      {name: 'KPM', count: 10},
      {name: 'RC', count: 20},
      {name: 'CCC', count: 30},
    ]
  }, 
];

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  count: number;
  level: number;
}



@Component({
  selector: 'rd-ric-info',
  templateUrl: './ric-info.component.html',
  styleUrls: ['./ric-info.component.scss']
})
export class RicInfoComponent implements OnInit {
  @ViewChild(MatTable, { read: true }) table: MatTable<any>;
  displayedColumns: string[] = ['name', 'count'];
  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      count: node.count,
      level: level,
    };
  }
ngOnInit(): void {
  
}
  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.level, 
      node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
}
