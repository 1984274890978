import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rd-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {


  @Input() sidebarData: any; // Input data for the sidebar content
  @Output() close: EventEmitter<void> = new EventEmitter<void>(); // Event emitter for closing the sidebar

  closeSidebar() {
    this.close.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
