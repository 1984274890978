
    <ul class="details-list">
      <li>
        <span>Xapp Name</span>
        <strong>It is a long established fact.</strong>
      </li>
      <li>
        <span>Name Space</span>
        <strong>It is a long established fact.</strong>
      </li>
      <li>
        <span>Xapp Name</span>
        <strong>It is a long established fact.</strong>
      </li>
      <li>
        <span>Name Space</span>
        <strong>It is a long established fact.</strong>
      </li>
      <li>
        <span>Creation Time</span>
        <strong>11:59 AM, 20 May 2024</strong>
      </li>
      <li>
        <span>Status</span>
        <strong class="status running">Running</strong>
        <strong class="status done">Done</strong>
        <strong class="status hold">On hold</strong>
        <strong class="status reject">Rejected</strong>
      </li>
    </ul>
