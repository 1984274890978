import { Component, OnInit } from '@angular/core';
import { truncateSync } from 'fs';

@Component({
  selector: 'rd-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  isLoading = false; // This property controls whether the spinner is shown or not


  constructor() { }

  ngOnInit(): void {
  }

}
