/*-
 * ========================LICENSE_START=================================
 * O-RAN-SC
 * %%
 * Copyright (C) 2019 AT&T Intellectual Property
 * %%
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *      http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================LICENSE_END===================================
 */
import { Component, OnDestroy, OnInit, ViewChild ,ChangeDetectorRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { XMXapp } from '../interfaces/app-mgr.types';
import { RicInstance } from '../interfaces/dashboard.types';
import { AppMgrService } from '../services/app-mgr/app-mgr.service';
import { InstanceSelectorService } from '../services/instance-selector/instance-selector.service';
import { LoadingDialogService } from '../services/ui/loading-dialog.service';
import { NotificationService } from '../services/ui/notification.service';
import { UiService } from '../services/ui/ui.service';
import { DeployDialogComponent } from '../ui/deploy-dialog/deploy-dialog.component';
import { OnboardComponent } from './../onboard/onboard.component';
import { CatalogDataSource } from './catalog.datasource';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AppConfigServiceService } from '../services/appconfig/app-config-service.service';



@Component({
  selector: 'rd-app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit, OnDestroy {



  dataSubject: any = {}; 
  darkMode: boolean;
  panelClass: string;
  displayedColumns: string[] = ['xapp_name', 'xapp_version', 'action'];
  private instanceChange: Subscription;
  private instanceKey: string;
  dataSource!: MatTableDataSource<any>;
  basePath:string;
  isToggled: boolean = false;

  toggleSlide(): void {
    this.isToggled = !this.isToggled;
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  items: any;

  constructor(
    private environment: AppConfigServiceService,
    private changeDetectorRef :ChangeDetectorRef,
    private loadingDialogService: LoadingDialogService,
    private appMgrService: AppMgrService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    public instanceSelectorService: InstanceSelectorService,
    private xappOnboarderService: XappOnboarderService,
    public ui: UiService) { 
      this.basePath = environment.config; }


  ngOnInit() {
    console.log(this.environment.config,"hello");

    this.xappOnboarderService.Refreshrequired.subscribe(Response=>{
      this.getonboardXappFile();
       }
    )

   
    
    this.getonboardXappFile();
    this.ui.darkModeState.subscribe((isDark) => {
      this.darkMode = isDark;
    });

    this.instanceChange = this.instanceSelectorService.getSelectedInstance().subscribe((instance: RicInstance) => {
      if (instance.key) {
        this.instanceKey = instance.key;
      }
    });

   
  }

  ngOnDestroy() {
    this.instanceChange.unsubscribe();
  }

  /*----- **
   Function : deleteXapp()
   Description : This function is to delete the  specific xapp name and version on catalog screen 
    -------**/

  deleteXapp(xapp: any) {
    let data = {
      xappName: xapp.name,
      HelmVersion: xapp.version
    }
       this.xappOnboarderService.deleteXappFile(data)
      .pipe(
        finalize(() => {
          this.loadingDialogService.stopLoading();
        })
      )
      .subscribe(
        (response: HttpResponse<Object>) => {
          this.notificationService.success('App delete succeeded!');
          this.xappOnboarderService.Refreshrequired.subscribe(Response=>{
            this.getonboardXappFile();
            
          })
        },
        ((her: HttpErrorResponse) => {
          this.notificationService.warn("App delete failed");
          let msg = her.message;
          if (her.error && her.error.message) {
            msg = her.error.message;
          }
        })
      );
    this.getonboardXappFile();
  }


  onDeployApp(app: XMXapp): void {
    if (this.darkMode) {
      this.panelClass = 'dark-theme';
    } else {
      this.panelClass = '';
    }
    const dialogRef = this.dialog.open(DeployDialogComponent, {
      panelClass: this.panelClass,
      width: '400px',
      maxHeight: '1000px',
      position: {
        top: '10%'
      },
      data: {
        xappName: app.name,
        HelmVersion: app.version,
        instanceKey: this.instanceKey
      }

    });
  }


  onboard(): void {
    if (this.darkMode) {
      this.panelClass = 'dark-theme';
    } else {
      this.panelClass = '';
    }
    const dialogRef = this.dialog.open(OnboardComponent, {
      panelClass: this.panelClass,
      width: '400px',
      maxHeight: '1000px',
      position: {
        top: '10%'
      },
      data: {
        instanceKey: this.instanceKey
      }

    });
  }

 
  refreshPage(): void {
    // Reload the entire page
    window.location.reload();
  }


  /*----- **
   Function : getonboardXappFile()
   Description : This function is to determine the chart list on catalog screen 
    -------**/

  getonboardXappFile() {
    this.xappOnboarderService.getonboardXappFile().subscribe({
      next: (res) => {
        
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray: any = [];

        for (let i = 0; i < Original.length; i++) {
          mainArray.push(Original[i][1])

        }
        const newData = mainArray.flat();
        this.dataSource = new MatTableDataSource(newData);
        this.dataSource.sort = this.sort;
        this.changeDetectorRef.detectChanges();
      },
      error: console.log,

    })
    
  }
}

