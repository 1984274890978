/*-
 * ========================LICENSE_START=================================
 * O-RAN-SC
 * %%
 * Copyright (C) 2020 AT&T Intellectual Property
 * %%
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================LICENSE_END===================================
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { finalize } from 'rxjs/operators';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { ErrorDialogService } from '../services/ui/error-dialog.service';
import { LoadingDialogService } from '../services/ui/loading-dialog.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NotificationService } from '../services/ui/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '../loading/loading.service';
import { InstanceSelectorService } from '../services/instance-selector/instance-selector.service';

@Component({
  selector: 'rd-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss']
})
export class OnboardComponent implements OnInit {
  private chartDataSubject = new BehaviorSubject<any[]>([]);

  submitted = false;
  isLoading = false;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  // public urlOnboardForm: FormGroup;
  public uploadFromLocalfile: FormGroup;
  file: any;

  constructor(
    private loadingService: LoadingService,
    private dialogRef: MatDialogRef<OnboardComponent>,
    private xappOnboarderService: XappOnboarderService,
    private errorDiaglogService: ErrorDialogService,
    private loadingDialogService: LoadingDialogService,
    private notificationService: NotificationService,
    private _dialogRef: MatDialogRef<OnboardComponent>,
    private instanceSelectorService: InstanceSelectorService,
    @Inject(MAT_DIALOG_DATA) private data
  ) { 
    this.uploadFromLocalfile = new FormGroup({
      signed_xapp_file: new FormControl('',),
      xapp_name: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z0-9']+$")]),
      xapp_version: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+(\.[0-9]+)+$")]),
    })

  }

  ngOnInit(): void {};
  configFile: File;
  controlsSchema: File;
  descriptor = {
    "config-file.json": {},
  }
  descriptor_url = {
    "config-file.json_url": "",
  }

  handleCategoryBanner2(file: any) {
    this.file = (<HTMLInputElement>file.target).files;
    console.log(this.file);


  }
  get registerFormControl() {
    return this.uploadFromLocalfile.controls;
  }

 

  uploadFromLocal() {
    
    if (this.uploadFromLocalfile.valid) {
      this.submitted = true;
    
    this.loadingService.showLoading();

    // this.isLoading = true;

    console.log(this.uploadFromLocalfile.value.xapp_name);

    const formData = new FormData();
    formData.append('signed_xapp_file', this.file[0], this.file[0].name);

    formData.append('xapp_name', this.uploadFromLocalfile.value.xapp_name);
    formData.append('xapp_version', this.uploadFromLocalfile.value.xapp_version);
    console.log(formData);
    this.xappOnboarderService.onboardXappFile(formData).subscribe(

     (data: any) => {
        let res = JSON.parse(data)
console.log(res.message, res)
        this._dialogRef.close();
        if (res.status == "false") {
          this.notificationService.error("Registeration failed");
        } else if (res.status == "true") {
          this.isLoading = true;
          this.notificationService.success("Registeration successfull");
          const newData=[];
          this.chartDataSubject.next(newData);
         this.handleUploadSuccess("updatedList");

        
        }
    },
      error => {
        this.isLoading = false;
        this.notificationService.error("Registeration failed");
        console.log(error);
        this._dialogRef.close();


        

      })

    setTimeout(() => {
      // Do your data loading or other tasks here
      this.loadingService.hideLoading();
    }, 2000);
  }
}


  // for uploading larger file on xapp onboard
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.uploadFile(file);
    }
  }

  uploadFile(file: File): void {
    const formData = new FormData();
    formData.append('file', file);
  }

  handleUploadSuccess(uploadedData: any) {
    this.instanceSelectorService.getUpdatedList(uploadedData);
  }

  public getUpdatedList(value:any) {
    


    // sessionStorage.setItem("value2",value)
		// this.instanceSelectorService.changeMessage(value)

  }
}

function requiredFileType(arg0: string): import("@angular/forms").ValidatorFn {
  throw new Error('Function not implemented.');
}
