import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs/Observable';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NotificationService } from '../services/ui/notification.service';
interface KpmDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  shortName: string;
  oid: string;
  description: string;
  eventTriggerStyleName: string;
  reportStylistName: string;
  headerFormat: string;
  messageFormat: string;
  ranFuncDescription: string;
  measurement: string;
}

interface RcDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  differentKey1: string;
  differentKey2: string;
  anotherDescription: string;
  ranFuncDescription: string;
}

interface CccDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  additionalKey1: string;
  additionalKey2: string;
  anotherDescription: string;
  ranFuncDescription: string;
}

interface response {
  inventoryName: string,
  plmnId: string,
  nbId: string,
  connectionStatus: string,
  healthCheckTimestampSent: string
}

export interface PeriodicElement {
  CellID: number;
  Prbusage: Number;
  pdcpUL: Number;
  pdcpDL: Number;
  rsrp: number;
  rsrq: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { CellID: 1006, Prbusage: 230, pdcpUL: 207.2, pdcpDL: 207.2, rsrp: 65, rsrq: 68 },
  { CellID: 1021, Prbusage: 240, pdcpUL: 207.2, pdcpDL: 207.2, rsrp: 68, rsrq: 61 },
  { CellID: 1008, Prbusage: 190, pdcpUL: 207.2, pdcpDL: 207.2, rsrp: 62, rsrq: 60 },
];

@Component({
  selector: 'rd-gnb-information',
  templateUrl: './gnb-information.component.html',
  styleUrls: ['./gnb-information.component.scss']
})
export class GnbInformationComponent implements OnInit {
  gnbInfo$: Observable<any>;
  selectedDetail: string = 'KPM'; // Default selected detail
  details: any = [];
  inventory: any;
  dataSource!: MatTableDataSource<any>;
  data: any = {};
  selectedCategory: string = '';
  selectedTab: string = 'general';
  gnbInfo: {};
  gnbData: any;
  inventoryName: string;
  connectionStatus: string;
  nbId: string;
  plmnId: string;
  healthCheckTimestampSent: string;
  header: string;
  selectedTabs = []
  displayedColumns: string[] = ['Cell-ID', 'Prbusage', 'pdcpUL', 'pdcpDL', 'connMean', 'connMax'];
  dataSource1 = ELEMENT_DATA;
  kpmData: [];
  rcData: [];
  cccData: [];
  responseData: any;
  gnbInfoData: any;
  gnbKpmData: any;
  kpm: any;
  rc: any;
  ccc: any;

  constructor(
    private xappOnboarderService: XappOnboarderService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,

  ) { }

  ngOnInit(): void {
  
    // for showing gnbinfo in first container 
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.xappOnboarderService.gnbDetails(id).subscribe(
          (response: any) => {
            let data1 = response.split(',');
            this.inventoryName = data1[0].split(':"')[1].replace('"', '');
            this.connectionStatus = data1[3].split(':"')[1].replace('"', '');
            this.nbId = data1[2].split(':"')[1].replace('"', '');
            this.plmnId = data1[1].split(':"')[1].replace('"', '');
            this.healthCheckTimestampSent = data1[4].split(':"')[1].replace('"', '').replace('}', '');
          },
          error => {
            console.error('Error fetching GNB info', error);
          }
        );
      }
    });

    if (this.route.snapshot?.params['id']) {
      this.getGnbInfo(this.route.snapshot?.params['id']);
    }

    this.updateinfo()
    if (this.route.snapshot?.params['id']) {
      this.fetchDetails(this.route.snapshot?.params['id']);
    }
  }

  tabsChange() {
    if (this.kpm != null && this.rc != null && this.ccc != null) {
      this.selectedTabs = ['kpm', 'rc', 'ccc']
      this.selectedCategory = 'kpm'
    }
    if (this.kpm != null && (this.rc == null && this.ccc == null)) {
      this.selectedTabs = ['kpm']
      this.selectedCategory = 'kpm'
    }
    if (this.rc != null && (this.kpm == null && this.ccc == null)) {
      this.selectedTabs = ['rc']
      this.selectedCategory = 'rc'
    }
    if (this.ccc != null && (this.kpm == null && this.rc == null)) {
      this.selectedTabs = ['ccc']
      this.selectedCategory = 'ccc'
    }
  }

  updateinfo(): void {
    this.gnbInfo = this.xappOnboarderService.getInfoData
  }

  selectCategory(category: string): void {
    this.selectedCategory = category;
    this.selectedTab = 'general'; // Default to general tab when category is changed
  }

  selectTab(tab: string): void {
    this.selectedTab = tab;
  }

  getGnbInfo(id: string) {
    this.xappOnboarderService.getGenInfo(id).subscribe({
      next: (res) => {
        let data = JSON.parse(res);
        console.log(data, "this.gnbKpmData")

        let Original = Object.entries(data);
        let mainArray: any = [];
        for (let i = 0; i < Original.length; i++) {
          mainArray.push(Original[i][1])
        }
        const newData = mainArray.flat();
        this.gnbInfoData = Object.values(newData)[0]
        if (this.gnbInfoData && typeof this.gnbInfoData === 'object') {
          const keys = Object.keys(this.gnbInfoData);
          console.log('Keys in Data:', keys); // Log the dynamic keys

          if (keys.length > 0) {
            const dynamicKey = keys[0]; // Get the first dynamic key (e.g., gnb_734_373_16b8cef1)
            console.log('Dynamic Key:', dynamicKey);
            const response = this.gnbInfoData[dynamicKey]; // Access the object under the dynamic key
            console.log('Response for dynamicKey:', response);

            // Now access the kpm, rc, and ccc properties
            if (response) {
              this.kpm = response.kpm || null;
              this.rc = response.rc || null;
              this.ccc = response.ccc || null;

              console.log('KPM:', this.kpm);
              console.log('RC:', this.rc);
              console.log('CCC:', this.ccc);
            } else {
              console.error('The object under dynamic key is undefined or null.');
            }
          } else {
            console.error('No keys found in the object.');
          }
        } else {
          console.error('Response is not an object:', data);
        }

        // let fetch = Object.keys(this.gnbInfoData)

        console.log(this.gnbInfoData, "this.gnbKpmData")
        // console.log(fetch, "fetch")
        this.tabsChange()
        console.log(this.selectedTabs, "this.selectedTabs")

      },
      error: console.log,
    })
  }


  fetchDetails(id: string) {
    this.xappOnboarderService.getKpmData(id).subscribe({
      next: (data: any) => {
        let apiResData = JSON.parse(data);
        let apiData = apiResData["measurement"];

        if (apiData["E2SM-KPM"]) {
          this.kpmData = apiData["E2SM-KPM"];
        }
        if (apiData["E2SM-RC"]) {
          this.rcData = apiData["E2SM-RC"];
        }
        if (apiData["E2SM-CCC"]) {
          this.cccData = apiData["E2SM-CCC"];
        }
      }
    });
  }

  updateToggleValue(data) {
    if (data) {
      let payload = data.map(item => ({
        "measId": item.measId,
        "state": item.active
      }));

      if (payload) {
        this.xappOnboarderService.updateToggleButton(payload)
          .subscribe(
            (response: HttpResponse<Object>) => {
              this.xappOnboarderService.Refreshrequired.subscribe(Response => {
              })
              this.notificationService.success('Updated sucessfully!');
            },
            ((her: HttpErrorResponse) => {
              this.notificationService.warn("Update failed");
              let msg = her.message;
              if (her.error && her.error.message) {
                msg = her.error.message;
              }
            })
          );
      }
    }
  }

  hasData(detail: string): boolean {
    if (this.inventory && this.inventory[detail.toLowerCase()]) {
      const detailData = this.inventory[detail.toLowerCase()];
      return detailData.ranFuncID !== 0;
    }
    return false;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  onSelect(detail: string) {
    console.log('Selected detail:', detail);
    this.selectedDetail = detail;
    console.log('Current selected details:', this.details[this.selectedDetail]); // Debug statement
    // this.fetchDetails(this.route.snapshot?.params['id'], detail); 
  }

}


