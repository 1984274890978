<!--
  ========================LICENSE_START=================================
  O-RAN-SC
  %%
  Copyright (C) 2019 AT&T Intellectual Property
  %%
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
  ========================LICENSE_END===================================
  -->
<div class="header">
  <div class="right-action">
    <button mat-mini-fab (click)="refreshPage()">
      <span class="material-symbols-outlined"> refresh </span>
    </button>
    <button mat-raised-button color="primary" (click)="onboard()">
      Onboard Xapp
    </button>
  </div>
</div>
<div class="app-control__section">
  <table mat-table [dataSource]="receivedData" matSort multiTemplateDataRows class="app-control-table">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Xapp Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element?.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="namespace">
      <mat-header-cell *matHeaderCellDef> Name Space</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element?.namespace }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element?.status }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="creationTime">
      <mat-header-cell *matHeaderCellDef> Creation Time </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.creationTime }}
      </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="port">
        <mat-header-cell *matHeaderCellDef > Port </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element?.instances ? element?.instances[0]?.port : ''}} </mat-cell>
      </ng-container> -->

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action</mat-header-cell>
      <!-- click on button should not expand/collapse the row -->
      <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <!-- <button mat-icon-button (click)="onConfigureApp(element)" class="customBtn mat-icon-button">
            <mat-icon matTooltip="Adjust settings">settings</mat-icon>
          </button> -->

        <button mat-icon-button (click)="undeploy(element)" class="customBtn mat-icon-button">
          <span class="material-symbols-outlined"> delete </span>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div [@messageExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          ">
          <!-- <div>
              txMessages:
            </div> -->
          <!-- <li *ngFor="let rxmessage of element.instances[0].rxMessages">
              <span>{{rxmessage?.rxmessage}}</span>
            </li> -->
          <!-- <div>
              rxMessages:
            </div> -->
          <!-- <li *ngFor="let txmessage of element.instances[0].txMessages">
              <span>{{txmessage?.txmessage}}</span>
            </li> -->
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="noRecordsFound">
      <mat-footer-cell *matFooterCellDef>No records found.</mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let element; columns: displayedColumns"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"></mat-row>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="message-row"></tr>
    <mat-footer-row *matFooterRowDef="['noRecordsFound']"
      [ngClass]="{ 'display-none': dataSource.rowCount > 0 }"></mat-footer-row>
  </table>

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>