import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardServiceService {

  getToken(){
    return localStorage.getItem('id_token')
   }

   setToken(token:any){
      localStorage.setItem('id_token', token)
   }

   isLoggedIn(){
      let tokenData:string |null =localStorage.getItem('id_token');
      // console.log("tokenStatus=====>", tokenData)
    return tokenData !=null ? true :false;
   }

}
