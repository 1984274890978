<!--
  ========================LICENSE_START=================================
  O-RAN-SC
  %%
  Copyright (C) 2019 AT&T Intellectual Property
  %%
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
  ========================LICENSE_END===================================
  -->
  <div class="app-control__section">
    <div class="header">
      <h3>Cell List</h3>
      <div class="right-action">
        <button mat-mini-fab (click)="refreshPage()">
          <span class="material-symbols-outlined"> refresh </span>
        </button>
      </div>
    </div>
    
    
    
        <table mat-table [dataSource]="dataSource" matSort class="catalog-table " class="catalog_sect"
            matSortActive="plmn_id" matSortDirection="asc">
    
            <!-- connection_status  -->
            <ng-container matColumnDef="cell_id">
                <mat-header-cell *matHeaderCellDef> Cell ID </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.cell_id}}</mat-cell>
            </ng-container>
    
    
            <!-- plmn_id-->
            <ng-container matColumnDef="pdcpbytesDL">
                <mat-header-cell *matHeaderCellDef > Down Link </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.down.pdcpbytesDL}} </mat-cell>
            </ng-container>
    
            <!-- nb_id  -->
            <ng-container matColumnDef="nbid">
                <mat-header-cell *matHeaderCellDef> Up Link</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.up.pdcpbytesUL}} </mat-cell>
    
            </ng-container>
  
            <ng-container matColumnDef="plmnid">
              <mat-header-cell *matHeaderCellDef> plmnid</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.plmid.plmnid}} </mat-cell>
  
          </ng-container>
  
    
    
            <!--Inventory_name -->
            <!-- <ng-container matColumnDef="Inventory_name">
    
                <mat-header-cell *matHeaderCellDef> Inventory name</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.Inventory_name}} </mat-cell>
    
            </ng-container> -->
    
            <ng-container matColumnDef="noRecordsFound">
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
    </div>