import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { AppConfigServiceService } from '../services/appconfig/app-config-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { Observable, Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'rd-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})

export class OverviewComponent implements OnInit {
  @ViewChild('Gnb', { static: true }) chartContainer!: ElementRef;
  @ViewChild('Xapps', { static: true }) chartContainerXapps!: ElementRef;
  @ViewChild('Container', { static: true }) container!: ElementRef;

  iFrame2: any = null;
  iFrame3: any = null;
  iFrame4: any = null;
  coordinates: any
  coordinatesData: any
  connected: any;
  disConnected: any
  receivedData: any;
  xappsRunning: number;
  xappsFailed: number;
  xappsTotalData: number;
  containerData: any[];
  containerRunning: number;
  containerFailed: number;
  containerPending: number;
  allContainerData: number;
  ueCount: any;
  cellCount: any;
  subscriptionCount: [];

  constructor(private environment: AppConfigServiceService,
    public sanitizer: DomSanitizer,
    private xappOnboarderService: XappOnboarderService,
    private changeDetectorRef: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.iFrames()
    this.createLineChart();
    this.getGnb();
    this.controlChartXappFile();
    this.getContainerData();
    this.getGnbUeCount();
    this.getGnbCellCount();
    this.getSubscriptionData();
  }

  /*----- **
  Function : iFrames() 
  Description : This function is to determine the Ric Infra Status list on Dashboard screen 
  -------**/

  iFrames() {
    this.environment.getTopology().subscribe(response => {
      const url = JSON.parse(response)
      this.iFrame2 = this.sanitizer.bypassSecurityTrustResourceUrl(url.memoryUsed);
    });

    this.environment.getTopology().subscribe(response => {
      const url = JSON.parse(response)
      this.iFrame3 = this.sanitizer.bypassSecurityTrustResourceUrl(url.cpuUsage);
    });

    this.environment.getTopology().subscribe(response => {
      const url = JSON.parse(response)
      this.iFrame4 = this.sanitizer.bypassSecurityTrustResourceUrl(url.cpuUsed);
    })
  }

  /*----- **
  Function : getGnb() 
  Description : This function is to determine the chart list on gnblist screen 
  -------**/

  getGnb() {
    this.xappOnboarderService.getGnb().subscribe({
      next: (res) => {
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray: any = [];

        for (let i = 0; i < Original.length; i++) {
          mainArray.push(Original[i][1])
        }
        const newData = mainArray.flat();
        let connected = [];
        let disConnected = [];
        newData.map(x => {
          if (x.connectionStatus == 'CONNECTED') {
            connected.push(x);
          } else {
            disConnected.push(x);
          }
        });
        this.coordinates = [
          { label: 'Connected', value: connected.length },
          { label: 'Disconnected', value: disConnected.length }
        ]

        this.coordinatesData = connected.length + disConnected.length;
        this.connected = connected.length;
        this.disConnected = disConnected.length;

        this.createGnbDonutChart(this.coordinates);

        this.changeDetectorRef.detectChanges();
      },
      error: console.log,
    })
  }

  private createGnbDonutChart(data: any): void {
    const element = this.chartContainer.nativeElement;
    d3.select(element).selectAll('*').remove(); // Clear previous chart

    const width = 300;
    const height = Math.min(width, 200);
    const radius = Math.min(width, height) / 2;

    const arc = d3.arc()
      .innerRadius(radius * 0.7)
      .outerRadius(radius * 0.9);

    const pie = d3.pie()
      .padAngle(1 / radius)
      .sort(null)
      .value((d: any) => d.value > 0 ? d.value : 1); // Ensure each segment has a minimum size

    // Check if all values are zero
    const allValuesZero = data.every((d: any) => d.value === 0);

    const color = d3.scaleOrdinal()
      .domain(data.map(d => d.label))
      .range(['#65e665', '#ff6961']); // Custom colors for connected/disconnected

    const svg = d3.select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [-width / 2, -height / 2, width, height])
      .attr('style', 'max-width: 100%; height: auto;');

    const paths = svg.append('g')
      .selectAll('path')
      .data(pie(data))
      .join('path')
      .attr('fill', (d: any) => {
        if (allValuesZero) {
          return '#d3d3d3'; // Grey color when all values are zero
        }
        return d.data.value === 0 ? '#d3d3d3' : color(d.data.label); // Grey for individual zero values
      })
      .attr('d', arc as any);

    // Add hover effect only when not all values are zero
    paths.on('mouseover', function (event, d) {
      if (!allValuesZero) {
        paths.attr('fill-opacity', 0.3); // Dim other segments
        d3.select(this).attr('fill-opacity', 1).attr('cursor', 'pointer'); // Highlight hovered

        svg.append('text')
          .attr('id', 'hover-text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '12px')
          .attr('dy', '-1.5em')
          .text(d.data.label + " : " + d.data.value);
      }
    });

    // Mouseout event to reset opacity
    paths.on('mouseout', function () {
      paths.attr('fill-opacity', 1); // Reset opacity
      svg.select('#hover-text').remove(); // Remove hover text
    });
  }

  controlChartXappFile() {
    this.xappOnboarderService.controlChartXappFile().subscribe({
      next: (res) => {
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray2: any = [];
        for (let i = 0; i < Original.length; i++) {
          mainArray2.push(Original[i][1])
        }
        const newData = mainArray2.flat();
        let running = [];
        let failed = [];
        newData.map(x => {
          if (x.status == "Running") {
            running.push(x);
          } else {
            failed.push(x);
          }
        });
        console.log(running.length);
        console.log(failed.length);
        this.receivedData = [
          { label: 'Running', value: running.length },
          { label: 'failed', value: failed.length }
        ]

        this.xappsTotalData = running.length + failed.length;
        this.xappsRunning = running.length;
        this.xappsFailed = failed.length;

        this.createXappsDonutChart(this.receivedData);

      },
      error: console.log,
    })
  }

  private createXappsDonutChart(data: any): void {
    const element = this.chartContainerXapps.nativeElement;
    d3.select(element).selectAll('*').remove(); // Clear previous chart

    const width = 300;
    const height = Math.min(width, 200);
    const radius = Math.min(width, height) / 2;

    const arc = d3.arc()
      .innerRadius(radius * 0.7)
      .outerRadius(radius * 0.9);

    const pie = d3.pie()
      .padAngle(1 / radius)
      .sort(null)
      .value((d: any) => d.value > 0 ? d.value : 1); // Ensure each segment has a minimum size

    // Check if all values are zero
    const allValuesZero = data.every((d: any) => d.value === 0);

    const color = d3.scaleOrdinal()
      .domain(data.map(d => d.label))
      .range(['#65e665', '#ff6961']); // Custom colors for connected/disconnected

    const svg = d3.select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [-width / 2, -height / 2, width, height])
      .attr('style', 'max-width: 100%; height: auto;');

    const paths = svg.append('g')
      .selectAll('path')
      .data(pie(data))
      .join('path')
      .attr('fill', (d: any) => {
        if (allValuesZero) {
          return '#d3d3d3'; // Grey color when all values are zero
        }
        return d.data.value === 0 ? '#d3d3d3' : color(d.data.label); // Grey for individual zero values
      })
      .attr('d', arc as any);

    // Add hover effect only when not all values are zero
    paths.on('mouseover', function (event, d) {
      if (!allValuesZero) {
        paths.attr('fill-opacity', 0.3); // Dim other segments
        d3.select(this).attr('fill-opacity', 1).attr('cursor', 'pointer'); // Highlight hovered

        svg.append('text')
          .attr('id', 'hover-text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '12px')
          .attr('dy', '-1.5em')
          .text(d.data.label + " : " + d.data.value);
      }
    });

    // Mouseout event to reset opacity
    paths.on('mouseout', function () {
      paths.attr('fill-opacity', 1); // Reset opacity
      svg.select('#hover-text').remove(); // Remove hover text
    });
  }

  // private createXappsDonutChart(data: any): void {
  //   const element = this.chartContainerXapps.nativeElement;
  //   const width = 300;
  //   const height = Math.min(width, 200);
  //   const radius = Math.min(width, height) / 2;

  //   const arc = d3.arc()
  //     .innerRadius(radius * 0.7)
  //     .outerRadius(radius * 0.9);

  //   const pie = d3.pie()
  //     .padAngle(1 / radius)
  //     .sort(null)
  //     .value((d: any) => d.value);

  //   const color = d3.scaleOrdinal()
  //     .domain(data.map(d => d.label))
  //     .range(['#65e665', '#ff6961']);

  //   const svg = d3.select(element)
  //     .append('svg')
  //     .attr('width', width)
  //     .attr('height', height)
  //     .attr('viewBox', [-width / 2, -height / 2, width, height])
  //     .attr('style', 'max-width: 100%; height: auto;');

  //   const paths = svg.append('g')
  //     .selectAll('path')
  //     .data(pie(data))
  //     .join('path')
  //     .attr('fill', (d: any) => color(d.data.label))
  //     .attr('d', arc as any);

  //   // Mouseover event
  //   paths.on('mouseover', function (event, d) {
  //     paths.attr('fill-opacity', 0.3);

  //     d3.select(this)
  //       .attr('fill-opacity', 1)
  //       .attr('cursor', 'pointer');

  //     svg.append('text')
  //       .attr('id', 'hover-text')
  //       .attr('text-anchor', 'middle')
  //       .attr('font-size', '12px')
  //       .attr('dy', '-1.5em')
  //       .text(d.data.label + " : " + d.data.value);
  //   });

  //   // Mouseout event
  //   paths.on('mouseout', function () {
  //     paths.attr('fill-opacity', 1);

  //     svg.select('#hover-text').remove();
  //   });
  // }

  getContainerData() {
    this.xappOnboarderService.getContainerStatus().subscribe({
      next: (res: any) => {
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray2: any = [];
        for (let i = 0; i < Original.length; i++) {
          mainArray2.push(Original[i][1])
        }
        const newData = mainArray2.flat();
        let running = [];
        let failed = [];
        let pending = [];

        newData.map(x => {
          if (x.status == "Running") {
            running.push(x);
          }
          if (x.status == "Failed") {
            failed.push(x);
          }
          if (x.status == "Pending") {
            pending.push(x);
          }
        });
        this.containerData = [
          { label: 'Running', value: running.length },
          { label: 'failed', value: failed.length },
          { label: 'pending', value: pending.length },
        ]

        this.containerRunning = running.length;
        this.containerFailed = failed.length;
        this.containerPending = pending.length;
        this.allContainerData = this.containerRunning + this.containerFailed + this.containerPending

        this.createContainerDonutChart(this.containerData);
      },
      error: console.log,
    })
  }

  private createContainerDonutChart(data: any): void {
    const element = this.container.nativeElement;
    d3.select(element).selectAll('*').remove(); // Clear previous chart

    const width = 300;
    const height = Math.min(width, 200);
    const radius = Math.min(width, height) / 2;

    const arc = d3.arc()
      .innerRadius(radius * 0.7)
      .outerRadius(radius * 0.9);

    const pie = d3.pie()
      .padAngle(1 / radius)
      .sort(null)
      .value((d: any) => d.value > 0 ? d.value : 1); // Ensure each segment has a minimum size

    // Check if all values are zero
    const allValuesZero = data.every((d: any) => d.value === 0);

    const color = d3.scaleOrdinal()
      .domain(data.map(d => d.label))
      .range(['#65e665', '#ff6961', '#f7ad4d']);

    const svg = d3.select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [-width / 2, -height / 2, width, height])
      .attr('style', 'max-width: 100%; height: auto;');

    const paths = svg.append('g')
      .selectAll('path')
      .data(pie(data))
      .join('path')
      .attr('fill', (d: any) => {
        if (allValuesZero) {
          return '#d3d3d3'; // Grey color when all values are zero
        }
        return d.data.value === 0 ? '#d3d3d3' : color(d.data.label); // Grey for individual zero values
      })
      .attr('d', arc as any);

    // Add hover effect only when not all values are zero
    paths.on('mouseover', function (event, d) {
      if (!allValuesZero) {
        paths.attr('fill-opacity', 0.3); // Dim other segments
        d3.select(this).attr('fill-opacity', 1).attr('cursor', 'pointer'); // Highlight hovered

        svg.append('text')
          .attr('id', 'hover-text')
          .attr('text-anchor', 'middle')
          .attr('font-size', '12px')
          .attr('dy', '-1.5em')
          .text(d.data.label + " : " + d.data.value);
      }
    });

    // Mouseout event to reset opacity
    paths.on('mouseout', function () {
      paths.attr('fill-opacity', 1); // Reset opacity
      svg.select('#hover-text').remove(); // Remove hover text
    });
  }

  // private createContainerDonutChart(data: any): void {
  //   const element = this.container.nativeElement;
  //   const width = 300;
  //   const height = Math.min(width, 200);
  //   const radius = Math.min(width, height) / 2;

  //   const arc = d3.arc()
  //     .innerRadius(radius * 0.7)
  //     .outerRadius(radius * 0.9);

  //   const pie = d3.pie()
  //     .padAngle(1 / radius)
  //     .sort(null)
  //     .value((d: any) => d.value);

  //   const color = d3.scaleOrdinal()
  //     .domain(data.map(d => d.label))
  //     .range(['#65e665', '#ff6961', '#f7ad4d']);

  //   const svg = d3.select(element)
  //     .append('svg')
  //     .attr('width', width)
  //     .attr('height', height)
  //     .attr('viewBox', [-width / 2, -height / 2, width, height])
  //     .attr('style', 'max-width: 100%; height: auto;');

  //   const paths = svg.append('g')
  //     .selectAll('path')
  //     .data(pie(data))
  //     .join('path')
  //     .attr('fill', (d: any) => color(d.data.label))
  //     .attr('d', arc as any);

  //   // Mouseover event
  //   paths.on('mouseover', function (event, d) {
  //     paths.attr('fill-opacity', 0.3);

  //     d3.select(this)
  //       .attr('fill-opacity', 1)
  //       .attr('cursor', 'pointer');

  //     svg.append('text')
  //       .attr('id', 'hover-text')
  //       .attr('text-anchor', 'middle')
  //       .attr('font-size', '12px')
  //       .attr('dy', '-1.5em')
  //       .text(d.data.label + " : " + d.data.value);

  //   });

  //   // Mouseout event
  //   paths.on('mouseout', function () {
  //     paths.attr('fill-opacity', 1);
  //     svg.select('#hover-text').remove();
  //   });

  // }

  private createLineChart(): void {
    const data = [
      {
        name: 'RU-1',
        color: '#65e665',
        values: [
          { date: new Date(2024, 0, 1), power: 200 },
          { date: new Date(2024, 1, 1), power: 120 },
          { date: new Date(2024, 2, 1), power: 33 },
          { date: new Date(2024, 3, 1), power: 21 },
          { date: new Date(2024, 4, 1), power: 51 },
          { date: new Date(2024, 5, 1), power: 190 },
          { date: new Date(2024, 6, 1), power: 120 },
          { date: new Date(2024, 7, 1), power: 85 },
          { date: new Date(2024, 8, 1), power: 221 },
          { date: new Date(2024, 9, 1), power: 101 },
          { date: new Date(2024, 10, 1), power: 0 },
          { date: new Date(2024, 11, 1), power: 0 }
        ]
      },
      {
        name: 'RU-2',
        color: '#ff6961',
        values: [
          { date: new Date(2024, 0, 1), power: 100 },
          { date: new Date(2024, 1, 1), power: 10 },
          { date: new Date(2024, 2, 1), power: 145 },
          { date: new Date(2024, 3, 1), power: 241 },
          { date: new Date(2024, 4, 1), power: 101 },
          { date: new Date(2024, 5, 1), power: 90 },
          { date: new Date(2024, 6, 1), power: 10 },
          { date: new Date(2024, 7, 1), power: 35 },
          { date: new Date(2024, 8, 1), power: 21 },
          { date: new Date(2024, 9, 1), power: 201 },
          { date: new Date(2024, 10, 1), power: 0 },
          { date: new Date(2024, 11, 1), power: 0 }
        ]
      }
    ];

    const margin = { top: 20, right: 30, bottom: 50, left: 50 };
    const width = 750 - margin.left - margin.right;
    const height = 240 - margin.top - margin.bottom;

    const svg = d3.select("#lineChart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleTime()
      .domain([new Date(2024, 0, 1), new Date(2024, 11, 31)])
      .range([0, width]);

    const maxYValue = d3.max(data.map(d => d.values).reduce((acc, val) => acc.concat(val), []).map(d => d.power)) as number;

    const y = d3.scaleLinear()
      .domain([0, maxYValue])
      .range([height, 0]);

    svg.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(d3.timeMonth.every(1))) // Show every month
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    svg.append("g")
      .call(d3.axisLeft(y).ticks(10));

    const line = d3.line<any>()
      .x((d: any) => x(d.date))
      .y((d: any) => y(d.power));

    data.forEach(series => {
      // Draw the line
      svg.append("path")
        .datum(series.values)
        .attr("fill", "none")
        .attr("stroke", series.color)
        .attr("stroke-width", 2)
        .attr("d", line);

      // Draw the circles
      svg.selectAll(".dot")
        .data(series.values)
        .enter().append("circle")
        .attr("cx", (d: any) => x(d.date))
        .attr("cy", (d: any) => y(d.power))
        .attr("r", 4)
        .attr("fill", series.color);

      // Add text labels
      svg.selectAll(".text")
        .data(series.values)
        .enter().append("text")
        .attr("x", (d: any) => x(d.date))
        .attr("y", (d: any) => y(d.power) - 10) // Adjust position to be above the circle
        .attr("text-anchor", "middle")
        .attr("fill", series.color)
        .text((d: any) => d.power)
        .style("font-size", "7px");// Reduce font size
    });
  }

  getGnbUeCount() {
    if (environment.demo) {
      this.xappOnboarderService.getUe().subscribe({
        next: (res) => {

          let data = JSON.parse(res);
          let Original = Object.entries(data);
          let mainArray: any = [];

          for (let i = 0; i < Original.length; i++) {
            let a = { "data": Original[i][1], "ue_id": Original[i][0] }
            console.log(a, 'a');
            mainArray.push(a)

          }
          const newData = mainArray.flat();
          this.ueCount = newData.length;
          console.log(this.ueCount, "uecount")
          this.changeDetectorRef.detectChanges();
        },
        error: console.log,

      })
    }
    else {
      this.xappOnboarderService.getUeIdCount().subscribe({
        next: (res: any) => {
          let data = JSON.parse(res);
          let Original = Object.entries(data);
          let mainArray2: any = [];
          for (let i = 0; i < Original.length; i++) {
            mainArray2.push(Original[i][1])
          }
          const newData = mainArray2.flat();
          this.ueCount = newData[0].ue_count;

        },
        error: console.log,
      })
    }
  }

  getGnbCellCount() {
    if (environment.demo) {
      this.xappOnboarderService.getCell().subscribe({
        next: (res) => {

          let data = JSON.parse(res);
          let Original = Object.entries(data);
          let mainArray: any = [];

          for (let i = 0; i < Original.length; i++) {
            let a = { "up": Original[i][1], "down": Original[i][1], "cell_id": Original[i][0], plmid: Original[i][1] }
            console.log(a, 'a');

            mainArray.push(a)

          }
          const newData = mainArray.flat();
          this.cellCount = newData.length;
          console.log(this.cellCount, "cellCount")
          this.changeDetectorRef.detectChanges();
        },
        error: console.log,
      })
    } else {
      this.xappOnboarderService.getCellIdCount().subscribe({
        next: (res: any) => {
          let data = JSON.parse(res);
          let Original = Object.entries(data);
          let mainArray2: any = [];
          for (let i = 0; i < Original.length; i++) {
            mainArray2.push(Original[i][1])
          }
          const newData = mainArray2.flat();
          this.cellCount = newData[0].cell_id_count;

        },
        error: console.log,
      })
    }
  }

  getSubscriptionData() {
    this.xappOnboarderService.getSubscriptionData().subscribe(
      (response) => {
        let data = JSON.parse(response);
        if (!data.message) {
          let Original = Object.entries(data);
          let mainArray2: any = [];
          for (let i = 0; i < Original.length; i++) {
            mainArray2.push(Original[i][1])
          }
          const newData = mainArray2.flat();
          this.subscriptionCount = newData.length;
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

}
